// import logo from './logo.svg';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from "@material-ui/core/CssBaseline";
//import { MaterialTheme } from './Pattern.js'
import { createBrowserHistory } from "history";

import './App.css';

import Home from './pages/home/home'
import SeniorScam from './pages/senior-scam/senior-scam';

export default function App() {
  const history = createBrowserHistory();
  return (
    <ThemeProvider >
      <CssBaseline />
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path='/senior-scam'>
            <SeniorScam />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
