import React, { Component } from 'react';
import './home.css';

import ReactGA from 'react-ga'
ReactGA.initialize('UA-206556151-1', {
  debug: true
});
ReactGA.pageview(window.location.pathname + window.location.search);

export default class Home extends Component {

  constructor(props){
    super(props)
    this.state = {
      tweenData: '100%'
    }
  }

  componentDidMount(){
    const tweenData = '10 150';
    this.setState({
      tweenData,
    });
  }

  render(){
    return (
      <div className="homepage">
        <div className="homepage-navigation">
          <h1>Naudicol</h1>
        </div>
        <div className="homepage-attention-grabber">
          <div className="break"></div>
          <h2>Collecting Market Research to Solve Societies Hardest Problems</h2>
          <p>Having conversations to design smarter solutions</p>
        </div>
        <div className="homepage-explaination">
          <p>
            This domain is used to run a few experiements on various business ideas.
            Rather than running off and spending months or years working on an new idea 
            without any market validation, we are conducting small tests. These tests are designed
            to see if there are others out, just like us looking to solve specific problems
            we are interestd in solving.
          </p>
          <div className="break"></div>
          <p>
            We monitor things like web traffic, form submissions, etc. The most important
            thing you can do to help us out is: If you landed on one of our testing pages
            and you agree with what was stated there, fill out the form letting us know
            you agree. If you believe the problem you landed on is a true problem but maybe
            we are describing things incorrectly, please fill out the form because we would
            love to learn from your perspective on the problem.
          </p>
          <div className="break"></div>
          <p>
            At the end of the day, the group managing this website is just bored at work
            wanting to focus on problems that interest us. Any insights, ideas, or feedback
            is greatly appreciated.
          </p>
          <div className="break"></div>
          <p>
            For questions email us: <a href="mailto:naudicolquestions@gmail.com">naudicolquestions@gmail.com</a>
          </p>
        </div>
      </div>
    )
  }
}
