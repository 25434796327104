import React from 'react';

import './radio_input.css'

export default function RadioInput(props){
  const inputType = props.inputType
  const inputLabel = props.inputLabel
  const inputValue = props.inputValue
  const inputId = `input-radio-field ${props.inputId}`
  const inputStateSetter = props.inputStateSetter
  const radioInputName = `input-radio ${props.inputSeriesName}`
  return (
    <div className="input-radio-component">
      <input className="input-radio-field" name={radioInputName} value={inputValue} type={inputType} id={inputId} onChange={(ev) => inputStateSetter(ev.target.value)}/>
      <label id="input-radio-label" for={inputId}>{inputLabel}</label>
    </div>
  )
};