import { Snackbar } from '@material-ui/core';
import React from 'react';
import Input from '../input/input';
import RadioFormSection from '../radio_form_section/radio_form_section';
import './form.css'

export default function Form(props){
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleClick = () => {
    setSnackbarOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const objects = props.formProps
  const onSubmitForm = props.onSubmitForm
  const formTitle = props.formTitle
  return (
    <div className="form">
      <form id={props.formId}>
        <h3>{formTitle}</h3>
        {/* {formSectionLoop(objects)} */}
        {objects.map((object, i) => {
          if(Array.isArray(object)){
            return <div className="input-container" key={i}>
              <RadioFormSection 
                array={object}
                radioFormTitle={object[0].radioFormTitle}
              />
            </div>
          } else {
            return <div className="input-container" key={i}>
              <Input 
                inputType={object.inputType}
                inputLabel={object.inputLabel}
                inputId={object.inputId}
                inputStateSetter={object.inputStateSetter}
              />
            </div>
          }
        })}
        <button value="submit" onClick={(ev) => {onSubmitForm(ev); handleClick()}}>Submit</button>
        <Snackbar 
          open={ snackbarOpen }
          autoHideDuration={ 4000 }
          onClose={handleClose}
          message="Information Submitted."
          // action={action}
        />
      </form>
      
    </div>
  )
};
