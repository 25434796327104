import React from 'react';
import './input.css'

export default function Input(props){
  const inputType = props.inputType
  const inputLabel = props.inputLabel
  const inputId = `input-field ${props.inputId}`
  const inputStateSetter = props.inputStateSetter
  return (
    <div className="input-component">
      <label id="input-label" for={inputId}>{inputLabel}</label>
      <input className="input-field" type={inputType} id={inputId} onChange={(ev) => inputStateSetter(ev.target.value)} />
    </div>
  )
};