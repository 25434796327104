import React, { useState } from 'react'
import './senior-scam.css'
import ImageSeniorPhone from '../../img/senior-phone.jpeg'
import Form from '../../components/form/form'
import axios from 'axios'

import ReactGA from 'react-ga'
ReactGA.initialize('UA-206556151-1', {
  debug: true
});
ReactGA.pageview(window.location.pathname + window.location.search);

const SENIOR_SCAM_SUBMISSION_URL = process.env.REACT_APP_SENIOR_SCAM_SUBMISSION_URL;

export default function SeniorScam(){
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [betaUser, setBetaUser] = useState('');
  const [interviewPreference, setInterviewPreference] = useState('')

  const formProps = [
    {
      "inputLabel": "Name",
      "inputType": "text",
      "inputId": "name-input",
      "inputState": name,
      "inputStateSetter": setName
    },
    {
      "inputLabel": "Email",
      "inputType": "email",
      "inputId": "email-input",
      "inputState": email,
      "inputStateSetter": setEmail
    },
    [
      {
        "radioFormTitle": "Do you want your family to be an early beta user?"
      },
      {
        "inputLabel": "Yes Please!",
        "inputType": "radio",
        "inputId": "beta-user-yes",
        "inputValue": "yes",
        "inputState": betaUser,
        "inputStateSetter": setBetaUser,
        "inputSeriesName": "beta-user"
      },
      {
        "inputLabel": "Add me to an email list, I may or may not respond",
        "inputType": "radio",
        "inputId": "beta-user-maybe",
        "inputValue": "maybe",
        "inputState": betaUser,
        "inputStateSetter": setBetaUser,
        "inputSeriesName": "beta-user"
      },
      {
        "inputLabel": "No thank you.",
        "inputType": "radio",
        "inputId": "beta-user-no",
        "inputValue": "no",
        "inputState": betaUser,
        "inputStateSetter": setBetaUser,
        "inputSeriesName": "beta-user"
      }
    ],
    [
      {
        "radioFormTitle": "Do you want to be involved in the solution we create? AKA is it okay for us to contact you and do some discovery of your situation?"
      },
      {
        "inputLabel": "Yes Please!",
        "inputType": "radio",
        "inputId": "interview-yes",
        "inputValue": "yes",
        "inputState": interviewPreference,
        "inputStateSetter": setInterviewPreference,
        "inputSeriesName": "interview"
      },
      {
        "inputLabel": "Add me to an email list, I may or may not respond",
        "inputType": "radio",
        "inputId": "interview-maybe",
        "inputValue": "maybe",
        "inputState": interviewPreference,
        "inputStateSetter": setInterviewPreference,
        "inputSeriesName": "interview"
      },
      {
        "inputLabel": "No thank you.",
        "inputType": "radio",
        "inputId": "interview-no",
        "inputValue": "no",
        "inputState": interviewPreference,
        "inputStateSetter": setInterviewPreference,
        "inputSeriesName": "interview"
      }
    ]
  ]

  const onSubmitForm = (ev) => {
    ev.preventDefault();
    console.log('state from form: ', {name, email, betaUser, interviewPreference});
    console.log('url env: ', SENIOR_SCAM_SUBMISSION_URL)
    axios({
      method: 'post',
      url: SENIOR_SCAM_SUBMISSION_URL,
      data: {
        name: name,
        email: email,
        betaUser: betaUser,
        interviewPreference: interviewPreference
      },
      headers: {
        "Content-Type": "application/json"
      }
    }).catch(err => {console.log("err: ", err)})
    document.getElementById('senior-scam-form').reset()
  }

  return (
    <div className='senior-scam page'>
      <div className='page-title container'>
        <h1>Senior Scams</h1>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <img src={ImageSeniorPhone} id="senior-texting-img" alt="Senior Texting"/>
          </div>
          <div className="col-md-4" />
        </div>
      </div>
      <div id="break-div"/>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="section-title">
              <h3>Our Goal</h3>
            </div>
          </div>
          <div className="col-md-9">
            <p>
              Scammers over the years have ramped up their push 
              into Americans lives taking billions of dollars away 
              from people. Most government agencies have a division 
              or department dedicated directly to combat these 
              scammers. In a recent <a href="https://www.ic3.gov/Media/PDF/AnnualReport/2020_IC3ElderFraudReport.pdf" target="_blank" rel="noopener noreferrer">
              FBI Annual Report in 2020</a> there 
              were over 100,000 victims with an average dollar loss 
              of just over $9,000.
            </p>
            <p id="bold">
              Our team is building a suite of products for families 
              to combat these scams. Our goal is to detect that a 
              scam is occurring in real-time and alert family members 
              to contact their loved ones to see if everything is okay.
            </p>
            <p>
              We are conducting market research currently to evaluate if
              people are willing to share their experience with scams. 
              This research will inform us of which method's scammers use 
              the most and which communication channels they use.
            </p>
          </div>
        </div>
      </div>
      <div id="break-div"/>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="section-title">
              <h3>Our Experience</h3>
            </div>
          </div>
          <div className="col-md-9">
            <p>
            We are an experienced group of developers who have spent 
            the last 10+ years developing communication software for 
            businesses. As part of building a better piece of software 
            for businesses to communicate with their customers, we 
            have developed expertise in building real-time AI models 
            to signal alerts about what is happening within the 
            conversation.
            </p>
            <p>
            We are planning to use our AI experience to build models that 
            can alert families of when their loved one is being scammed.
            </p>
          </div>
        </div>
      </div>
      <div id="break-div"/>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="section-title">
              <h3>Who We Are</h3>
            </div>
          </div>
          <div className="col-md-9">
            <p>
            Nic Baughman - Product and technology minded individual. 
            My grandparents have been the target of senior scams 
            for years so it is a problem I am looking forward to resolving.
            </p>
            <p>
            LinkedIn: <a href="https://www.linkedin.com/in/nicbaughman" target="_blank" rel="noopener noreferrer">
            https://www.linkedin.com/in/nicbaughman</a>
            </p>
          </div>
        </div>
      </div>
      <div id="break-div"/>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="section-title">
              <h3>Our Ask</h3>
            </div>
          </div>
          <div className="col-md-9">
            <p>
            We can't build this alone and want to learn from peoples 
            experiences of dealing with scammers for loved ones. 
            If you believe that this is a problem to solve and you 
            want to be part of the solution, please fill out your 
            information below.
            </p>
            <p>
            By filling out that form you give us valuable insight 
            that what we believe to be a problem resonates with you. 
            Also we would like to send you updates and keep you in 
            the loop as the software is developed.
            </p>
          </div>
        </div>
      </div>
      <div id="break-div"/>
      <div className="container">
        <Form 
          formProps={formProps}
          onSubmitForm={onSubmitForm}
          formTitle="Sign Up"
          formId = "senior-scam-form"
        />
      </div>
    </div>
    
  )
}