import React from 'react';
import RadioInput from '../radio_input/radio_input';

import './radio_form_section.css'

const radioInputLoop = ({radioObjects}) => {
  let display = []
  for(let i=0; i < radioObjects.length; i++){
    if(!radioObjects[i].radioFormTitle){
      let item = <div className="input-radio-container" key={i}>
        <RadioInput 
          inputType={radioObjects[i].inputType}
          inputLabel={radioObjects[i].inputLabel}
          inputId={radioObjects[i].inputId}
          inputStateSetter={radioObjects[i].inputStateSetter}
          inputValue={radioObjects[i].inputValue}
          inputSeriesName={radioObjects[i].inputSeriesName}
        />
      </div>
      display.push(item)
    }
  }
  return display
}

export default function RadioFormSection(props){
  const array = props.array
  const radioFormTitle = props.radioFormTitle
  return (
    <div className="radio-selection-section">
      <div className="radio-form-section">
        <p>{radioFormTitle}</p>
        <div className="input-radio-options-component">
        {radioInputLoop({radioObjects: array})}
        </div>
      </div>
    </div>
    
  )
};